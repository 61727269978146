import React from 'react';
import Flowchart from './Flowchart';
import Tabs from '../sdlc/Tabs';
import SideNav from '../Dashboard/SideNav';

function DashBoard() {
  return (
<>
<div className="flex h-screen">
  <div className="flex-none h-full" style={{ width: '4.73%', height: '100vh' }}>
    <SideNav />
  </div>
  <div className="flex-none" style={{ width: '55.27%', overflowY: 'auto' }}>
    <Flowchart />
  </div>
  <div className="flex-none" style={{ width: '40%', overflowY: 'auto', backgroundColor: '#ffffff', opacity: 0.8, backgroundImage: 'radial-gradient(#9898a0 0.5px, #ffffff 0.5px)', backgroundSize: '10px 10px' }}>
    <Tabs />
  </div>
</div>
</>
  );
}

export default DashBoard;