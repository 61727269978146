import React, { useEffect, useContext, useState } from 'react';
import { UserContext } from "../contexts/user.context";
import axios from 'axios';
import Header from '../Builder/Header';
import Stats from './Stats';
import SideNav from './SideNav';
import LiveWebditesDetails from './LiveWebditesDetails';
import Profile from './Profile';

export default function Dashboard() {
  const { user } = useContext(UserContext);
  const [userDetails, setUserDetails] = useState(null);
  const [showProfile, setShowProfile] = useState(false);

  useEffect(() => {
    const storeEmailInLocalStorage = (email) => {
      localStorage.setItem('userEmail', email);
    };

    const fetchUserDetails = async (email) => {
      try {
        const response = await axios.get('https://free-ap-south-1.cosmocloud.io/development/api/userdetails', {
          params: {
            email: email,
            limit: 10,
            offset: 0
          },
          headers: {
            projectId: '66dc452ca54723b1c14ba1e9',
            environmentId: '66dc452ca54723b1c14ba1ea'
          }
        });

        if (response.data && response.data.data.length > 0) {
          const apiUser = response.data.data[0];
          if (apiUser.email === email) {
            console.log("_id from API: ", apiUser._id);
            setUserDetails(apiUser);
            //store the user details in local storage
            localStorage.setItem('userDetails', JSON.stringify(apiUser));
            console.log("User Details: ", apiUser);
          } else {
            setShowProfile(true);
          }
        } else {
          setShowProfile(true);
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
        setShowProfile(true);
      }
    };

    if (user && user.profile && user.profile.email) {
      console.log("Email from Dash: ", user.profile.email);
      storeEmailInLocalStorage(user.profile.email);
      fetchUserDetails(user.profile.email);
    } else {
      const storedEmail = localStorage.getItem('userEmail');
      if (storedEmail) {
        fetchUserDetails(storedEmail);
      }
    }
  }, [user]);

  const liveProductsCount = userDetails?.liveProducts?.length || 0;
  const flowBuilderCount = userDetails?.flowBuilder?.length || 0;
  const questionnairesCount = userDetails?.questionnaires?.length || 0;

  console.log("Live Products Count: ", liveProductsCount);
  console.log("Flow Builder Count: ", flowBuilderCount);
  console.log("Questionnaires Count: ", questionnairesCount);

  const totalCount = liveProductsCount + flowBuilderCount + questionnairesCount;

  const handleProfileSave = (updatedUserDetails) => {
    setUserDetails(updatedUserDetails);
    setShowProfile(false);
  };

  return (
    <>
      <div className="h-screen w-screen flex overflow-hidden antialiased text-gray-800 bg-white" >
        {/* section body side nav */}
        <SideNav />
        <div className="flex-1 flex flex-col">
          {/* section body top nav */}
          <Header />
          {/* section body header */}
          {/* main content */}
          <main className="flex-grow flex min-h-0 border-t overflow-auto">
            {/* section update to tickets */}

            {/* section content */}
            <section
              aria-label="main content"
              className="flex min-h-0 flex-col flex-auto border-l overflow-auto"
            >
              {/* content navigation */}

              {showProfile ? (
                <Profile email={user?.profile?.email} onSave={handleProfileSave} />
              ) : (
                <>
                  <Stats
                    totalCount={totalCount}
                    liveProductsCount={liveProductsCount}
                    flowBuilderCount={flowBuilderCount}
                    questionnaireCount={questionnairesCount}
                  />
                  {/* <LiveWebditesDetails /> */}
                </>
              )}
            </section>
          </main>
        </div>
      </div>
    </>
  )
}