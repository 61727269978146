import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import reportWebVitals from './reportWebVitals';
import { ResponseProvider } from './sdlc/ResponseContext';
import { UserProvider } from './sdlc/UserContext'; // Import UserProvider

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <DndProvider backend={HTML5Backend}>
      <ResponseProvider> {/* Wrap the App with ResponseProvider */}
        <UserProvider> {/* Wrap the App with UserProvider */}
          <App />
        </UserProvider>
      </ResponseProvider>
    </DndProvider>
  </React.StrictMode>
);

reportWebVitals();