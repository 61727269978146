import React from 'react'
import APICreator from '../Baas/APICreator'

export default function Test() {
  return (
    <>
    <APICreator />
    </>
  )
}
