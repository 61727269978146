import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { UserProvider } from "./contexts/user.context";
import Login from "./pages/Login.page";
import PrivateRoute from "./pages/PrivateRoute.page";
import Signup from "./pages/Signup.page";
import Error from "./pages/Error.page";
import LandingPage from "./pages/LandingPage";
import Test from './pages/Test';
import Preview from "./pages/Preview";
import PreviewInNewTab from "./pages/PreviewInNewTab"; 
import Builder from "./pages/Builder";
import CompanyHtmlContent from "./pages/CompanyHtmlContent";
import PublishedPage from "./pages/PublishedPage";
import ChatbotInterface from './Chatbot/ChatbotInterface';
import Flowdemo from './Chatbot/Flowchart';
import SDLCDashboard from './sdlc/SDLCDashboard';
import DashBoard from './Dashboard/Dashboard';
import Profile from './Dashboard/Profile';
import "./tailwind.css";
import Flowchart from './FlowCode/DashBoard';

function App() {
  const [data, setData] = useState(null);

  return (
    <BrowserRouter>
      <UserProvider>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/test" element={<Test />} />
          <Route path="/Dashboard" element={<DashBoard />} />
          <Route path="/builder/instantpresent" element={<Builder />} />
          <Route path="/Dashboard/profile" element={<Profile />} />
          <Route path="/builder/chatbot" element={<ChatbotInterface />} />
          <Route path="/flowchart" element={<Flowdemo />} />
          <Route path="/builder/flow" element={<Flowchart />} />
          <Route path="/chatbot" element={<ChatbotInterface />} />
          <Route path="/preview" element={<Preview />} />
          <Route path="/published/:id" element={<PublishedPage />} />
          <Route path="/builder/chatdoc" element={<SDLCDashboard />} />
          <Route
            path="/preview-in-new-tab"
            element={<PreviewInNewTab />}
          />
          <Route
            path="/d/:workplaceUrl"
            element={<CompanyHtmlContent />}
          />
          <Route element={<PrivateRoute />}>
          </Route>
          <Route path="*" element={<Error />} />
        </Routes>
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;